<template>
    <v-navigation-drawer
        v-model="$store.state.showNavigationBar"
        mini-variant
        app
        :permanent="!isMobile"
    >
        <v-list 
            dense 
            nav
        >
            <v-list-item 
                link
                :ripple="false"
                @click="$router.push('/')"
            >
                <v-list-item-icon class="pt-4">
                    <v-icon color="primary">fa-solid fa-house</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>

        <v-list
            class="bottom"
            dense
            nav
        >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :ripple="false"
                :href="item.path"
                target="_blank"
            >
                <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
      items: Array
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.name === 'xs'
        } 
    }
};
</script>

<style lang="scss">
    .bottom {
        position: fixed;
        bottom: 20px;
    }
</style>