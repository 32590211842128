<template>
  <v-app-bar
    app
    floating
    elevation="0"
  >
    <v-container 
      class="pa-0" 
      fluid
    >
      <v-row 
        class="no-padding"
      >
        <v-col 
          cols="3"
        >
          <navigation-bar-button></navigation-bar-button>
        </v-col>
        <v-col
          cols="9"
        >
          <back-to-projects-button></back-to-projects-button>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
    components: {
      'navigation-bar-button': require('@/components/navigation/NavigationBarButton.vue').default,
      'back-to-projects-button': require('@/components/navigation/BackToProjectsButton.vue').default
    },

};
</script>

<style scoped>
  .no-padding {
    padding: 25px 0px 5px;
  }
</style>
