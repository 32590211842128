import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showNavigationBar: false,
    menuItems: [
      {
        path: "/",
        label: "Home",
        icon: "fas fa-home-alt fa-lg",
      },
      {
        path: "/projects",
        label: "Projects",
        icon: "fa-solid fa-code fa-lg",
      },
      {
        path: "/about",
        label: "About",
        icon: "fa-solid fa-user fa-lg",
      },
    ],
    socialMediaItems: [
      {
        path: "https://github.com/hjnewman3",
        label: "GitHub",
        icon: "fa-brands fa-github fa-lg",
      },
      {
        path: "https://gitlab.com/chip594",
        label: "GitLab",
        icon: "fa-brands fa-gitlab fa-lg",
      },
      {
        path: "https://www.linkedin.com/in/hjnewman3/",
        label: "Linked-In",
        icon: "fa-brands fa-linkedin-in fa-lg",
      },
      {
        path: "https://www.facebook.com/chip594",
        label: "Facebook",
        icon: "fa-brands fa-facebook-f fa-lg",
      },
      {
        path: "https://twitter.com/chip594",
        label: "Twitter",
        icon: "fa-brands fa-twitter fa-lg",
      },
    ],
  },
  getters: {
  },
  mutations: {
    toggleNavigationBar(state) {
      state.showNavigationBar = !state.showNavigationBar
    }
  },
  actions: {
  },
  modules: {
  }
});
