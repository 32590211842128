<template>
    <v-row>
        <v-col 
            cols="4"
        ></v-col>
        <v-col
            class="
                text-subtitle-1 
                secondary--text 
                px-5 
                d-flex 
                justify-center"
            cols="4"
        >
            {{ time }}
        </v-col>
        <v-col 
            class="
                text-subtitle-1 
                secondary--text 
                px-5 
                d-flex 
                justify-end"
            cols="4"
        >
            {{ date }}
        </v-col>
    </v-row>
</template>

<script>
import { format } from 'date-fns'

export default {
    data() {
        return {
            date: '',
            time: ''
        }
    },
    methods: {
        getDate() {
            this.date = format(new Date(), 'MMM d')
            this.time = format(new Date(), 'h:mm:ss a')
            setTimeout(this.getDate, 1000)
        }
    },
    mounted() {
        this.getDate()
    }
}
</script>
