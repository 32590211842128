<template>
  <v-app id="app">

    <system-bar 
      class="navigation"
    ></system-bar>

    <app-bar
      class="background"
    ></app-bar>

    <navigation-bar 
      class="navigation"
      :items="$store.state.socialMediaItems"

    ></navigation-bar>

    <v-main 
      class="background" 
      app
    >
      <router-view/>
    </v-main>
    
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: { 
    'system-bar': require('@/components/SystemBar.vue').default,
    'app-bar': require('@/components/navigation/AppBar.vue').default,
    'navigation-bar': require('@/components/navigation/NavigationBar.vue').default
   }
};
</script>

<style>
@import "assets/css/site.css";
</style>
