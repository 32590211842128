import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/es5/util/colors'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa', // default - only for display purposes
      },
    theme: {
      themes: {
        light: {
            background: "#0a192f",
            navigation: "#172a46",
            primary: "#2b6be4",
            secondary: "#BBDEFB",
            accent: colors.amber.base,
            error: colors.red.base,
            warning: colors.yellow.base,
            info: colors.teal.base,
            success: colors.green.base
        }
      }
    }
});
