<template>
    <div class="pdf-text-extractor pa-6 secondary--text">
        <p>
            <h2>Comming Soon!!!</h2>
        </p>
        <h1>This is an PDF Text Extractor page</h1>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>