<template>
  <div>
    <vue-markdown :source="mdFile"></vue-markdown>
  </div>
</template>

<script>

export default {
  data() {
    return {
      mdFile: '',
    }
  },
  components: {
    'vue-markdown': require('vue-markdown').default,
  },
  created() {
    var client = new XMLHttpRequest();
    client.open('GET', '/readme/CombineText.md');
    let self = this;
    client.onreadystatechange = function() {
      self.mdFile = client.responseText;
    }
    client.send();
  }
}
</script>
