import Vue from 'vue'
import VueRouter from 'vue-router'

import AboutView from '../views/AboutView'
import HomeView from '../views/HomeView'
import ProjectView from '../views/ProjectView'
import CombineTextView from '../views/projects/CombineTextView'
import CryptoToolsView from '../views/projects/CryptoToolsView'
import NameListEditorView from '../views/projects/NameListEditorView'
import PdfTextExtractorView from '../views/projects/PdfTextExtractorView'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectView,
  },
  {
    path: "/projects/combine-text",
    name: "combine-text",
    component: CombineTextView,
  },
  {
    path: "/projects/crypto-tools",
    name: "crypto-tools",
    component: CryptoToolsView,
  },
  {
    path: "/projects/name-list-editor",
    name: "name-list-editor",
    component: NameListEditorView
  },
  {
    path: "/projects/pdf-text-extractor",
    name: "pdf-text-extractor",
    component: PdfTextExtractorView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
