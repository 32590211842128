<template>
  <v-container 
    class="project pa-6 secondary--text"
    fluid
    fill-height
  >
    <v-col>
      <v-row
        v-for="(item, index) in projectList"
        :key="item.label"
        @click="toggleDescription(index)"
        class="py-4"
      >
        <v-card
          class="mx-auto"
          color="secondary"
          width="80vw"
        >
          <v-img
            class="secondary--text"
            max-height="240"
            position="center center"
            :src="item.image"
          >
            <v-card-title 
              class="text-h5"
            >
              {{ item.label}}
            </v-card-title>
          </v-img>

          <v-card-actions>
            <v-btn
            @click="$router.push(item.path)"
            color="primary"
            text
            >
            Check It Out
          </v-btn>

        <v-spacer></v-spacer>

          <v-btn
            class="primary--text"
            text
          > Description 
            <v-icon 
              class="pl-4"
              v-if="showDescription === index"
              color="primary"
            >
              fa-solid fa-chevron-up
            </v-icon>
            <v-icon
              class="pl-4"
              v-else
              color="primary"
            >
              fa-solid fa-chevron-down
            </v-icon>
          </v-btn>
        </v-card-actions>
        
        <v-expand-transition>
          <div v-if="showDescription === index">
            <v-card-text>
              <component v-bind:is="item.description"></component>
            </v-card-text>
          </div>
          </v-expand-transition>
        </v-card>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        projectList: [
          {
            path: "/projects/crypto-tools",
            label: "Crypto Tools",
            description: "crypto-tools-description",
            image: require('@/../public/img/projects/crypto-tools.jpg'),
            icon: "fa-brands fa-python"
          },
          {
            path: "/projects/combine-text",
            label: "Combine Text",
            description: "combine-text-description",
            image: require('@/../public/img/projects/combine-text.png'),
            icon: "fa-brands fa-python"
          },
          {
            path: "/projects/pdf-text-extractor",
            label: "PDF Text Extractor",
            description: "pdf-text-extractor-description",
            image: require('@/../public/img/projects/pdf-extractor.jpg'),
            icon: "fa-brands fa-python"
          },
          {
            path: "/projects/name-list-editor",
            label: "Name List Editor",
            description: "name-list-editor-description",
            image: require('@/../public/img/projects/name-list-editor.jpg'),
            icon: "fa-brands fa-java"
          }
        ],
      showDescription: null
      }
    },
    components: { 
      'crypto-tools-description': require('@/components/projects/descriptions/CryptoToolsDescription.vue').default,
      'combine-text-description': require('@/components/projects/descriptions/CombineTextDescription.vue').default,
      'pdf-text-extractor-description': require('@/components/projects/descriptions/PdfTextExtractorDescription.vue').default,
      'name-list-editor-description': require('@/components/projects/descriptions/NameListEditorDescription.vue').default
    },
    methods: {
      toggleDescription(index) {
        if (this.showDescription === index) {
          this.showDescription = null
        }
        else {
          this.showDescription = index
        }
      }
    }
  }
  </script>

  
  