<template>
    <v-container 
        class="crypto-tools pa-6 secondary--text"
        fluid
    >
        <p>
            <h2>Comming Soon!!!</h2>
        </p>
        <h1>This is an Cryptography Tools page</h1>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus cumque
        iste repudiandae aut ea esse maiores, minima vero aliquid eligendi at
        accusamus provident fugit. Nulla a velit voluptate quos exercitationem.
        </p>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            tab: null,
            cryptoTools: [
                {
                    label: "GCD",
                    component: "gcd"
                },
                {
                    label: "Inverse",
                    component: "inverse",
                },
                {
                    label: "Square Multiply",
                    component: "square-multiply"
                },
                {
                    label: "RSA",
                    component: "rsa"
                }
            ]
        }
    },
    components: {
        'gcd': require('@/components/projects/crypto-tools/Gcd.vue').default,
        'inverse': require('@/components/projects/crypto-tools/Inverse.vue').default,
        'square-multiply': require('@/components/projects/crypto-tools/SquareMultiply.vue').default,
        'rsa': require('@/components/projects/crypto-tools/Rsa.vue').default
    }
}
</script>

<style>

</style>