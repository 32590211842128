<template>
    <v-btn
        v-if="isProjectsChildPage"
        @click="$router.push('/projects')"
        color="primary"
        text
    >
        <v-icon 
            class="pr-2"
        >
            fa-sharp fa-solid fa-arrow-rotate-left
        </v-icon> 
            Back to Projects
    </v-btn>
</template>

<script>
export default {
    computed: {
        isProjectsChildPage() {
            return this.$route.path.startsWith('/projects/')
        }
    }
}
</script>
