<template>
    <v-system-bar 
        app
        height="30" 
    >
        <live-date-time></live-date-time>
    </v-system-bar>
</template>

<script>
export default {
    components: { 
        'live-date-time': require('@/components/Tools/LiveDateTime.vue').default
    }
}
</script>
