<template>
  <v-container 
    class="home pa-6"
    fluid
    fill-height
  >
    <v-row
      class="align-center justify-center mt-6"
    >
      <v-col
        cols="12"
        sm="8"
      >
        <div class="pl-2">
          <div
            class="text-subtitle-1 primary--text pb-2"
          >
            Hi, my name is
          </div>
          <div
            class="text-h2 secondary--text font-weight-bold pb-4"
          >
            Chip Newman.
          </div>
          <div
            class="text-h6 secondary--text py-2"
          >
            Husband • Father • Techie • Gamer • Nerd • Traveler
          </div>
          <div class="pt-8">
            <v-btn class="primary mr-2" outlined @click="$router.push('/projects')">My Projects</v-btn>
            <v-btn class="primary ml-2" outlined @click="$router.push('/about')">About Me</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters></v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Home',
    components: {},
  }
</script>

<style scoped>
  .img {
    background-size: cover;
    height: 100vh;
    background-image: linear-gradient(to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)), url('@/../public/img/computer-chip.jpg');
  }
</style>
