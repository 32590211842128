<template>
    <v-btn
        v-if="isMobile"
        class="background"
        @click.stop="$store.commit('toggleNavigationBar')"
        elevation="0"
        :ripple="false"
    >
        <v-icon
            v-if="!$store.state.showNavigationBar"
            color="primary"
        >
            fa-solid fa-angle-right
        </v-icon>
        <v-icon 
            v-else
            color="primary"
        >
            fa-solid fa-angle-left
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.name === 'xs'
       } 
    }
}
</script>

<style>

</style>